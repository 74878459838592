; (function ($, undefined) {
    "use strict";

    // Disable inital GMap3 loading
    $.gmap3(false);

    // Name and defaults
    var pluginName = "gmap3Upgrade",
        defaults = {
            container: '<div class="canvas">',
            remove: true,
            mapOptions: {},
            markerOptions: {}
        },
        loaded = false;

    // Actual plugin constructor
    function Plugin(element, options) {
        this.element = $(element);
        this.settings = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = pluginName;

        this.init();
    }

    // Avoid Plugin.prototype conflicts
    $.extend(Plugin.prototype, {
        init: function () {
            var url = this.element.attr('src');
            this.staticOptions = $.deparam(url.split('?', 2)[1], true);
            this.markers = $.map($.makeArray(this.staticOptions.markers), function (marker) {
                var values = marker.split('|');
                var options = {};
                var positions = [];
                $.each(values, function (i, value) {
                    var temp = value.split(':', 2);
                    if (temp.length === 2) options[temp[0]] = temp[1];
                    else positions.push(value.split(','));
                });

                return $.map(positions, function (position) {
                    return $.extend({
                        position: position
                    }, this.settings.markerOptions);
                }.bind(this));
            }.bind(this));

            if (loaded) {
                this.upgrade();
            } else {
                $.getScript('https://maps.google.com/maps/api/js?sensor=false' + (this.staticOptions.key ? '&key=' + this.staticOptions.key : null), function () {
                    loaded = true;
                    this.upgrade();
                }.bind(this));
            }
        },
        upgrade: function () {
            var container = $(this.settings.container).insertAfter(this.element);
            if (this.settings.remove) {
                this.element.remove();
            }

            // Parse size position etc. into appropriate google maps object
            jQuery.each(this.markers, function (i, marker) {
                var oldAnchor = marker.icon.anchor;
                var oldSize = marker.icon.scaledSize;
                var oldPosition = marker.position;

                marker.position = new google.maps.LatLng(oldPosition[0], oldPosition[1]);
                marker.icon.anchor = new google.maps.Point(oldAnchor.x, oldAnchor.y);
                marker.icon.scaledSize = new google.maps.Size(oldSize.width, oldSize.height);
            });

            var splittedCenter = this.staticOptions.center.split(',');
            var center = new google.maps.LatLng(splittedCenter[0], splittedCenter[1]);
            if (!center && this.markers.length > 0) {
                center = this.markers[0].position;
            }

            var gmap3 = container.gmap3($.extend({
                center: center,
                zoom: this.staticOptions.zoom
            }, this.settings.mapOptions));

            gmap3.marker(this.markers).then(function (markers) {
                this.markers = markers;

                if (this.markers.length > 1) {
                    gmap3.fit();
                }

                if (this.settings.onUpgraded !== undefined) {
                    this.settings.onUpgraded.call(this, container, gmap3);
                }
            }.bind(this));
        }
    });

    // Plugin wrapper
    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" + pluginName, new Plugin(this, options));
            }
        });
    };
})(jQuery);