; (function ($, undefined) {
	"use strict";

	// Name and defaults
	var pluginName = "dynamicFilter",
		defaults = {
			// Data attribute with filter names as values
			dataFilter: 'filter',
			// Optional data attribute with filter options (descendants of filter)
			dataFilterOption: 'filter-option',
			// Triggered after initialisation
			onInitialize: function () { },
			// Triggered after reset
			onResetFilter: function () { },
			// Triggered after set filter
			onSetFilter: function (filter) { },
			// Set filter triggered by code
			onSetFilterValue: function (filterName, filterValue, previousFilterValue) { }
		};

	// Actual plugin constructor
	function Plugin(element, options) {
		this.element = $(element);
		this.settings = $.extend({}, defaults, options);
		this._defaults = defaults;
		this._name = pluginName;

		this.init();
	}

	// Avoid Plugin.prototype conflicts
	$.extend(Plugin.prototype, {
		init: function () {
			this.filter = this.getFilter();

			// Track changes to filter
			this.element.change(function (e) {
				var target = $(e.target),
					dataFilter = target.closest('[data-' + this.settings.dataFilter + ']', this.element);

				if (dataFilter.length) {
					this.setFilterValue(dataFilter, this.getFilterValue(dataFilter), true);
				}
			}.bind(this));

			// Initialize callback
			this.settings.onInitialize.call(this);
		},
		resetFilter: function () {
			$.each(this.filter, function (name) {
				this.setFilterValue(name, null);
			}.bind(this));

			// Reset filter callback
			this.settings.onResetFilter.call(this);
		},
		getFilter: function () {
			var filter = {};
			this.element.find('[data-' + this.settings.dataFilter + ']').each(function (i, element) {
				var dataFilter = $(element),
					filterName = dataFilter.data(this.settings.dataFilter),
					filterValue = this.getFilterValue(dataFilter);

				if (filterValue) {
					filter[filterName] = filterValue;
				}
			}.bind(this));

			return filter;
		},
		getFilterValue: function (dataFilter) {
			// Convert name to element
			if (typeof dataFilter === 'string') dataFilter = this.getDataFilterElement(dataFilter);

			var dataFilterOptionSelector = '[data-' + this.settings.dataFilterOption + ']',
				dataFilterOptions = dataFilter.find(dataFilterOptionSelector),
				filterValue;

			if (dataFilterOptions.length) {
				// Get array of checked values
				filterValue = dataFilterOptions.find(':checked').addBack(':checked').closest(dataFilterOptionSelector).map(function (i, element) {
					var dataFilterOption = $(element);

					return dataFilterOption.data(this.settings.dataFilterOption);
				}.bind(this)).get();

				// Remove empty arrays
				if (!filterValue.length) filterValue = null;
			} else {
				// Get text value
				filterValue = dataFilter.prop('value');
			}

			return filterValue;
		},
		setFilter: function (filter) {
			filter = $.isPlainObject(filter) ? filter : this.filter;

			$.each(filter, function (name, values) {
				this.setFilterValue(name, values);
			}.bind(this));

			// Set filter callback
			this.settings.onSetFilter.call(this, filter);
		},
		setFilterValue: function (dataFilter, filterValue, noUpdate) {
			// Convert name to element
			if (typeof dataFilter === 'string') dataFilter = this.getDataFilterElement(dataFilter);

			var filterName = dataFilter.data(this.settings.dataFilter),
				previousFilterValue = this.filter[filterName];

			if (filterValue) {
				this.filter[filterName] = filterValue;
			} else {
				delete this.filter[filterName];
			}

			if (!noUpdate) {
				var dataFilterOptionSelector = '[data-' + this.settings.dataFilterOption + ']',
					dataFilterOptions = dataFilter.find(dataFilterOptionSelector);

				if (dataFilterOptions.length) {
					// Get checkboxes, radio buttons and select options
					var inputs = dataFilterOptions.find('input[type=checkbox],input[type=radio],option').addBack('input[type=checkbox],input[type=radio],option');
					if ($.isArray(filterValue) && filterValue.length) {
						// Set checked based on filter value
						inputs.each(function (i, element) {
							var input = $(element),
								dataFilterOption = input.closest(dataFilterOptionSelector),
								dataFilterOptionValue = dataFilterOption.data(this.settings.dataFilterOption),
								checked = $.inArray(dataFilterOptionValue, filterValue) >= 0;

							input.prop('checked', checked);
						}.bind(this));
					} else {
						// Remove all checked
						inputs.prop('checked', false);
					}
				} else {
					// Set text value
					dataFilter.prop('value', filterValue);
				}
			}

			// Set filter value callback
			this.settings.onSetFilterValue.call(this, filterName, filterValue, previousFilterValue);
		},
		getDataFilterElement: function(filterName) {
			return this.element.find('[data-' + this.settings.dataFilter + '=' + filterName + ']');
		},
		getDataFilterOptionElement: function (dataFilter, filterValue) {
			if (typeof dataFilter === 'string') dataFilter = this.getDataFilterElement(dataFilter);

			return dataFilter.find('[data-' + this.settings.dataFilterOption + '=' + filterValue + ']');
		},
		generateUrl: function (url, excludedFilterName, excludedFilterValue) {
			// Sort filter names and join values
			var filter = {};
			$.each(Object.keys(this.filter).sort(), function (i, filterName) {
				if (excludedFilterName && !excludedFilterValue) return;
				var filterValue = this.filter[filterName];

				if ($.isArray(filterValue)) {
					if (excludedFilterName && excludedFilterValue) {
						filterValue = $.grep(filterValue, function (value) {
							return value != excludedFilterValue;
						});
					}

					filterValue = filterValue.join(',');
				} else if (filterValue == excludedFilterValue) {
					filterValue = null;
				}

				if (filterValue) {
					filter[filterName] = filterValue;
				}
			}.bind(this));

			// Append query string to URL
			var queryString = $.param(filter);
			if (queryString) url += '?' + queryString;
			return url;
		}
	});

	// Plugin wrapper
	$.fn[pluginName] = function (options) {
		return this.each(function () {
			if (!$.data(this, "plugin_" + pluginName)) {
				$.data(this, "plugin_" + pluginName, new Plugin(this, options));
			}
		});
	};
})(jQuery);