/*
 * Panorama Studios
 */

// Disable inital GMap3 loading
$.gmap3(false);

$(document).ready(function () {
	// Responsive menu
	$('header').each(function () {
		var element = $(this),
			body = $(document.body),
			responsiveNav = $('.responsive-nav');

		var toggler = $('<div class="toggler toggler--closed"><span>Menu</span></div>').click(function () {
			body.toggleClass('open');
			responsiveNav.toggleClass('collapsed');
			toggler.toggleClass('toggler--closed');
		}).appendTo(element);
	});

	// Header affix
	$('.headroom').each(function () {
		var element = $(this);
		var headroom = element.headroom({
			offset: 50,
		});
		if (element.hasClass('headroom')) {
			$(document.body).addClass('headroom--enabled');
		}
	});

	// flipper on moblie
	$('.contact-box > div').each(function () {
		var element = $(this);

		element.on("touchend", function (e) {
			element.toggleClass('open');
		});
	});


	// Menu in intro
	$('.intro-block-frontpage__list strong').each(function () {
		var element = $(this);

		element.on("touchend", function (e) {
			element.next().toggleClass('open');
		});
	});

	// Search toggler
	$('.search').each(function () {
		var element = $(this),
			toggler = $('.search__toggler');

		toggler.click(function () {
			element.toggleClass('search--closed');
			toggler.toggleClass('search__toggler--closed');
		}).appendTo(element);
	});

	// Slideshow
	$('.slideshow').each(function () {
		var element = $(this);
		var cssClass = element.attr('class');
		if (element.children().length > 1) {
			element.children('.slide').removeClass('slide--active');
			element.lightSlider({
				item: 1,
				slideMargin: 0,
				addClass: cssClass,
				mode: 'fade',
				cssEasing: 'linear',
				auto: true,
				loop: true,
				adaptiveHeight: true,
				pause: 13000,
				controls: false,
			});
		}

	});

	// Match height
	$('.product__text-container').matchHeight();

	// Match height
	$('.message__title, .intro-block-frontpage__block, .tabs__content ul li span').matchHeight({
		byRow: true,
		property: 'min-height'
	});

	// Open language on touch
	$('.languages').each(function () {
		var element = $(this);

		element.on("touchend", function (e) {
			element.toggleClass('open');
		});
	});

	// Tabs
	$('.tabs__control-item').click(function () {
		var tab_id = $(this).attr('data-tab');

		$('.tabs__control-item').removeClass('tabs__control-item--active');
		$('.tabs__content').removeClass('tabs__content--active');

		$(this).addClass('tabs__control-item--active');

		var tab = $("#" + tab_id);
		tab.addClass('tabs__content--active');

		var tableWrapper = tab.find('.table-wrapper');
		if (tableWrapper.length > 0) {

			if (tableWrapper.get(0).clientWidth < tableWrapper.get(0).scrollWidth) {
				tableWrapper.addClass('table-wrapper--scroll-more');
			}
			else {
				tableWrapper.removeClass('table-wrapper--scroll-more');
			}
		}
	});

	// Responsive table
	$('table').not('.route__wrapper').wrap('<div class="table-wrapper">');

	// Responsive table wrapper
	$(window).resize(function () {

		var tableWrapper = $('.table-wrapper');
		if (tableWrapper.length > 0) {

			if (tableWrapper.get(0).clientWidth < tableWrapper.get(0).scrollWidth) {
				tableWrapper.addClass('table-wrapper--scroll-more');
			}
			else {
				tableWrapper.removeClass('table-wrapper--scroll-more');
			}
		}
	});

	// Change color of checkboxes
	$('.filter__checkbox').each(function () {
		var span = $(this);
		var checkbox = span.find('input');
		var label = span.next();

		//set initial state.
		if (checkbox.is(":checked")) {
			label.addClass('filter__button--active');
		}

		// Set event
		checkbox.change(function () {

			if (checkbox.is(":checked")) {
				label.addClass('filter__button--active');
			}
			else {
				label.removeClass('filter__button--active');
			}
		});
	});

	// Category filter
	$('.category-filter__input').each(function () {
		var element = $(this);
		element.removeAttr('onchange');

		element.change(function (e) {
			var target = $('[name=' + element.find('option:selected').val() + ']');

			if (target.length) {
				$('html, body').animate({
					scrollTop: target.offset().top
				}, 500);

				return false;
			}

			e.preventDefault();
		});
	});

	// Dealer filter
	var dealersMap = null, dealersCluster = null, dealersMarkers = [], dealersMarkerOptions = {};
	$('.dealer-filter').dynamicFilter({
		onInitialize: function () {
			this.url = window.location.protocol + "//" + window.location.host + window.location.pathname;
			this.container = $('#content .dealers');
			this.items = this.container.children('.dealer');


			// Remove filter button
			this.element.find('.filter__search').remove();

			this.element.find('.filter__checkbox').each(function () {
				var checkboxSpan = $(this);
				var chechbox = checkboxSpan.find('input');
				chechbox.attr('data-filter-option', checkboxSpan.attr('data-value'));
			});

			// Fetch all items and reload filter
			console.log('has filter:' + !$.isEmptyObject(this.filter));

			var hasFilter = !$.isEmptyObject(this.filter);
			if (hasFilter) {
				this.container.addClass('loading');

				this.container.load(this.url + ' #content .dealers > *', function () {
					this.items = this.container.children('.dealer');
					this.setFilter();
					console.log('setting filter:' + this.items);

				}.bind(this));


			}
		},
		onSetFilterValue: function (filterName, filterValue, previousFilterValue) {
			console.log('filter activated');

			// Update container items
			var hasFilter = false,
				filteredItems = this.items.detach();
			$.each(this.filter, function (name, values) {
				hasFilter = true;
				var filterSelector = [];
				if ($.isArray(values)) {
					$.each(values, function (i, value) {
						filterSelector.push('[data-filter-' + name + '~="' + value + '"]');
					});
				} else {
					filterSelector.push('[data-filter-' + name + '*="' + values.toLowerCase() + '"]');
				}
				filteredItems = filteredItems.filter(filterSelector.join(','));
			});
			this.container.append(filteredItems);
			this.container[(hasFilter ? 'add' : 'remove') + 'Class']('filtered');

			this.container.removeClass('loading');

			// Update map
			if (dealersMap && dealersCluster) {
				// Remove existing markers (from cluster and map)
				$.each(dealersMarkers, function (i, marker) {
					dealersCluster.remove(marker);
					marker.setMap(null);
				});

				// Get positions of filtered items
				var filteredMarkers = $.makeArray(filteredItems.map(function () {
					var position = $(this).data('position');
					var data = getDealerData($(this));
					var markerOptions = {};
					markerOptions.icon = {};

					markerOptions.icon.url = (window.baseUrl || '/') + 'images/pointer-dealer' + data.type + '.png';
					markerOptions.icon.anchor = new google.maps.Point(11, 35),
					markerOptions.icon.scaledSize = new google.maps.Size(22, 35)

					if (position) {
						return $.extend({
							visible: true,
							position: position.split(','),
							data: data
						}, markerOptions);
					}
				}));

				// Add markers (to map and cluster) and fit
				dealersMap.marker(filteredMarkers).then(function (markers) {
					var map = dealersMap;

					$.each(markers, function (i, marker) {
						dealersCluster.add(marker);
					});

					dealersMarkers = markers;

					addWindowToPointers(map, markers)
					addClickToDealers();
					addZoomToCluster();
				}).map()
				//.then(function (map) {
				//	dealersMap.get(0).set('maxZoom', 8);
				//})
				//.fit()
				.then(function () {
					var geocoder = new google.maps.Geocoder();
					var country = $(".filter__select option:selected");

					if (country.val != null)
					{
						geocoder.geocode({ 'address': country.text() }, function (results, status) {
							if (status == "OK") {
								dealersMap.get(0).setCenter(results[0].geometry.location);
							}
						});
					}
				})
				.then(function (map) {
					dealersMap.get(0).setZoom(6);
				});
			}
			// Change URL to match filters
			if (window.history) {
				history.replaceState(this.filter, null, this.generateUrl(this.url));
			}
		}
	});

	// Project filter
	$('.project-filter').dynamicFilter({
		onInitialize: function () {
			this.url = window.location.protocol + "//" + window.location.host + window.location.pathname;
			this.container = $('#content .projects');
			this.items = this.container.children('.project');

			// Remove filter button
			this.element.find('.filter__search').remove();

			this.element.find('.filter__checkbox').each(function () {
				var checkboxSpan = $(this);
				var chechbox = checkboxSpan.find('input');

				chechbox.attr('data-filter-option', checkboxSpan.attr('data-value'));
			});

			// Fetch all items and reload filter
			var hasFilter = !$.isEmptyObject(this.filter);
			if (hasFilter) {
				this.container.addClass('loading');

				this.container.load(this.url + ' #content .projects > *', function () {
					this.items = this.container.children('.project');
					this.setFilter();
				}.bind(this));
			}
		},
		onSetFilterValue: function (filterName, filterValue, previousFilterValue) {
			// Update container items
			var hasFilter = false,
				filteredItems = this.items.detach();
			$.each(this.filter, function (name, values) {
				hasFilter = true;
				var filterSelector = [];
				if ($.isArray(values)) {
					$.each(values, function (i, value) {
						filterSelector.push('[data-filter-' + name + '~="' + value + '"]');
					});
				} else {
					filterSelector.push('[data-filter-' + name + '*="' + values.toLowerCase() + '"]');
				}
				filteredItems = filteredItems.filter(filterSelector.join(','));
			});
			this.container.append(filteredItems);
			this.container[(hasFilter ? 'add' : 'remove') + 'Class']('filtered');

			this.container.removeClass('loading');

			// Change URL to match filters
			if (window.history) {
				history.replaceState(this.filter, null, this.generateUrl(this.url));
			}
		}
	});

	// Lightbox
	$('#content').lightGallery({
		selector: '[rel=lightbox]',
		download: false
	});

	// Route form
	$('.form--route').each(function () {
		var element = $(this);
		var button = element.find('button');
		var destinationAddress = element.find('input[type=text]');
		var destination = button.attr('data-address');

		button.click(function (e) {
			var route = {
				saddr: destinationAddress.val(),
				daddr: destination
			};

			window.open('https://maps.google.com/?' + $.param(route), '_blank');

			e.preventDefault();
		});
	});

	// Toastr
	//var toastData = aspJsVars['toastData'];
	//if (toastData) {
	//	// Set options
	//	toastr.options = {
	//		progressBar: true,
	//		closeButton: true,
	//		timeOut: 5000,
	//		extendedTimeOut: 5000,
	//		showDuration: 0,
	//		target: 'header',
	//		iconClasses: {
	//			info: 'toast'
	//		},
	//		titleClass: 'date',
	//		messageClass: 'title'
	//	};

	//	var cookieName = 'toast',
	//		ignoredIds = Cookies.get(cookieName);



	//	$.each($.parseJSON(toastData), function () {
	//		var data = this;

	//		if (ignoredIds) ignoredIds += ',' + data.id;
	//		else ignoredIds = data.id;

	//		Cookies.set(cookieName, ignoredIds, { expires: 1 });

	//		toastr.info(data.message, data.title, {
	//		}).on({
	//			click: function () {
	//				// Add id to cookie and go to URL
	//				//ignoreId(data.id);
	//				document.location = data.url;
	//			}
	//		});
	//	});
	//}

	// Smooth scroll
	$('a[href^="#"]').click(function () {
		var target = $(this.hash);
		if (!target.length) {
			target: $('[name=' + this.hash.slice(1) + ']');
		}
		if (target.length) {
			$('html, body').animate({
				scrollTop: target.offset().top - 77
			}, 500);

			return false;
		}
	});

	// Google Maps
	$('.container--content-wrapper').each(function () {
		var content = $(this);
		var maps = $('.google-maps');
		var wrapper = $('.google-maps__wrapper');

		function resizeGoogleMaps() {
			var documentWidth = $(document).width();
			var side = (documentWidth - content.width()) / 2;
			var mapsWidth = wrapper.width();
			maps.width(mapsWidth + side);
		}

		$(window).resize(function () {
			resizeGoogleMaps();
		});

		resizeGoogleMaps();
	});

	$('.google-maps:not(.google-maps--dealers) img').gmap3Upgrade({
		mapOptions: {
			zoom: 11,
			scrollwheel: false,
			streetViewControl: false
		},
		markerOptions: {
			clickable: false,
			icon: {
				url: (window.baseUrl || '/') + "images/marker-icon.png",
				anchor: {
					x: 107,
					y: 118
				},
				scaledSize: {
					width: 190,
					height: 133
				}
			}
		},
		onUpgraded: function (container, map) {
			// Custom logic for vacancies filter
			if (container.parents('.dealer-maps').length > 0) {
				// Save map and marker options
				dealersMap = map;
				dealersMarkerOptions = this.settings.markerOptions;

				// Create cluster
				map.cluster({
					markers: this.markers,
					cb: function (markers) {

						if (markers.length > 1)
						{
							return {
								content: '<div class="maps-cluster">' + markers.length + '</div>',
								x: -24,
								y: -24
							};
						}
					}
				}).then(function (cluster) {
					// Store cluster and markers
					dealersCluster = cluster;
					dealersMarkers = cluster.markers();
				});
			}
		}
	});

	// Dealers google maps
	$('.dealers').each(function() {
		var element = $(this);
		htmlMarkers = element.find('.dealer');

		// Load Google Maps API once
		$.ajaxSetup({
			cache: true
		});

		// Load the script and add the api key
		$.getScript('https://maps.google.com/maps/api/js?sensor=false&key=AIzaSyCzqCN925kiMiZUD8h3QU4C6goEN7dg8zM', function () {
			// Create markers from html data
			var markers = [];
			jQuery.each(htmlMarkers, function (i, val) {
				val = $(val);

				var data = getDealerData(val);
				var pos = data.position.split(',');


				markers[i] = {
					position: new google.maps.LatLng(pos[0], pos[1]),
					data: data,
					options: {
						icon: {
							url: (window.baseUrl || '/') + 'images/pointer-dealer' + data.type + '.png',
							anchor: new google.maps.Point(11, 35),
							scaledSize: new google.maps.Size(22, 35)
						}
					}
				}
			});

			// Remember the marker options
			dealersMarkerOptions = {
				icon: {
					anchor: new google.maps.Point(11, 35),
					scaledSize: new google.maps.Size(22, 35)
				}
			}

			// Render map
			dealersMap = $('.google-maps').gmap3({
				center: [32.54681317351514, 14.0000000],
				zoom: 3,
				scrollwheel: false,
				mapTypeId: google.maps.MapTypeId.ROADMAP,
				streetViewControl: false
			})
			// Render clusters
			.cluster({
				size: 50,
				markers: markers,
				cb: function (markers) {
					if (markers.length > 1) { // 1 marker stay unchanged (because cb returns nothing)
						return {
							content: function () {
								var hasAgriculture = false;
								var hasHoritculture = false;

								jQuery.each(markers, function (i, val) {
									if (val.data.type == '-agrarisch') hasAgriculture = true;
									if (val.data.type == '-tuinbouw') hasHoritculture = true;
									if (val.data.type == '-beide') { hasAgriculture = true; hasHoritculture = true; }
								});

								if (hasAgriculture == true && hasHoritculture == true) {
									return "<div class='maps-cluster maps-cluster--both'>" + markers.length + "</div>"
								}

								if (hasAgriculture == true) {
									return "<div class='maps-cluster maps-cluster--agriculture'>" + markers.length + "</div>"
								}

								if (hasHoritculture == true) {
									return "<div class='maps-cluster maps-cluster--horticulture'>" + markers.length + "</div>"
								}

								return "<div class='maps-cluster'>" + markers.length + "</div>"
							},
							x: -24,
							y: -24
						};
					}
				}
			})
			// Zoom in and center on one click when cluster
			//.on({
			//    click: function (m, event) {
			//        var map = this.get(0);
			//        map.setCenter(event.LatLng);
			//        map.setZoom(map.getZoom() + 1);
			//    }
			//})

			// Store cluster and markers and create info windows
			.then(function (cluster) {
				var map = this.get(0);
				var markers = cluster.markers();

				dealersCluster = cluster;
				dealersMarkers = markers;

				addWindowToPointers(map, markers)

				dealersMap.get(0).addListener('zoom_changed', function () {
					addZoomToCluster();
				});

				addClickToDealers();
				addZoomToCluster();
			})

			// Fit in view
			.fit();
		});
	});

	$('.dealer').each(function () {

		$(this).click(function () {
			var latLng = $(this).data("position").split(",");
			dealersMap.get(0).setCenter(new google.maps.LatLng(latLng[0], latLng[1]));
			dealersMap.get(0).setZoom(14);
		});
	});
	
	// Get the data information from html dealers
	function getDealerData(val) {
		var data = {};

		data.company = val.data('company') ? val.data('company') : '';
		data.type = val.data('type') ? val.data('type') : '';
		data.street = val.data('street') ? val.data('street') : '';
		data.city = val.data('city') ? val.data('city') : '';
		data.zipcode = val.data('zipcode') ? val.data('zipcode') : '';
		data.telephone1 = val.data('telephone1') ? val.data('telephone1') : '';
		data.telephone2 = val.data('telephone2') ? val.data('telephone2') : '';
		data.email = val.data('email') ? val.data('email') : '';
		data.website = val.data('website') ? val.data('website') : '';
		data.position = val.data('position') ? val.data('position') : '';

		return data;
	}

	// Create info windows for the markers
	function addWindowToPointers(map, markers) {

		for (var i = 0; i < markers.length; i++) {
			var marker = markers[i];
			var data = marker.data;

			var infoWindowContent = '<div class="info-window__content"> <h2>' + data.company + '</h2>';
			if (data.street) infoWindowContent += '<span>' + data.street + '</span>';
			if (data.city || data.zipcode) {
				infoWindowContent += '<span>';

				if (data.zipcode) {
					infoWindowContent += data.zipcode + ' ';
				}

				if (data.city) {
					infoWindowContent += data.city;
				}

				infoWindowContent += '</span>';
			}
			if (data.telephone1 || data.telephone2) {
				var telephoneAdded = false;
				infoWindowContent += '<span>'

				if (data.telephone1) {
					infoWindowContent += '<a href="tel:' + data.telephone1 + '">' + data.telephone1 + '</a>';
					telephoneAdded = true;
				}

				if (data.telephone2) {
					if (telephoneAdded) infoWindowContent += '/ ';
					infoWindowContent += '<a href="tel:' + data.telephone2 + '">' + data.telephone2 + '</a>';
				}

				infoWindowContent += '</span>'
			}
			if (data.email) infoWindowContent += '<a href="mailto:' + data.email + '">' + data.email + '</a>';
			if (data.website) infoWindowContent += '<a href="' + data.website + '" target="_blank">' + data.website + '</a>';
			infoWindowContent += '</div>';

			var infowindow = new google.maps.InfoWindow();

			google.maps.event.addListener(marker, 'click', (function (marker, content, infowindow) {
				return function () {
					infowindow.setContent(content);
					infowindow.open(map, marker);
				};
			})(marker, infoWindowContent, infowindow));
		}
	}

	// Zoom to dealer when clicked on a dealer from the list
	function addClickToDealers() {
		$('.dealer').each(function () {
			$(this).click(function () {

				// Go to pointer location and zoom
				var selectedMarker = $(this);
				var latLng = selectedMarker.data("position").split(",");
				dealersMap.get(0).setCenter(new google.maps.LatLng(latLng[0], latLng[1]));
				dealersMap.get(0).setZoom(14);

				// Get selected poiner with company name
				var marker;
				$.each(dealersMarkers, function (i, val) {
					mark = $(this);

					if (val.data.company == selectedMarker.data('company')) {
						marker = val;
					}
				});

				if (marker != null) {
					google.maps.event.trigger(marker, 'click');
				} 
			});
		});
	}

	// Zoom one lever when clicking on a cluster
	function addZoomToCluster() {
		//console.log(dealersCluster);

		//$('.maps-cluster').each(function () {
		//	var cluster = $(this);

		//	cluster.click(function () {
		//		console.log("dit werkt")
		//	});
		//});
	}

	// Smooth scroll
	$('a[href*=\\#]').click(function () {
		var target = $(this.hash);

		if (!target.length) {
			target = $('[name=' + this.hash.slice(1) + ']');
		}

		if (target.length) {
			$('html, body').animate({
				scrollTop: target.offset().top
			}, 500);

			return false;
		}
	});

	// Change target for external sites
	var getDomainName = function (hostName) {
		return hostName.substring(hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1);
	};
	$('a:not([target])').filter(function () {
		var link = $(this);
		if (link.filter('[rel~="external"]').length) return true; // Link rel attribute contains 'external'

		var href = link.attr('href');
		if (new RegExp('\.pdf$', 'i').test(href)) return true; // Link to PDF file

				if (new RegExp('^https?://', 'i').test(href)) {
			var linkHostName = getDomainName(href.substring(href.indexOf('/') + 2, href.indexOf('/', 8)));
			if (!new RegExp('(^|\.)' + getDomainName(document.location.hostname) + '$', 'i').test(linkHostName)) {
				// Link is to different domain name
				return true;
			}
		}

		return false;
	}).attr('target', '_blank');
});